import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {path: "/", component: () =>("../views/HomePage.vue")},
        {path: "/register", component: () =>("../views/RegisterPage.vue")},
        {path: "/sign-in", component: () =>("../views/SignInPage.vue")},
        {path: "/feed", component: () =>("../views/FeedPage.vue")}
    ],
});

export default router;