import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAQBneLkrM5LZ8oaC_vEmBq7MbTeJLpNAQ",
  authDomain: "microlearn-18c99.firebaseapp.com",
  projectId: "microlearn-18c99",
  storageBucket: "microlearn-18c99.appspot.com",
  messagingSenderId: "472533231759",
  appId: "1:472533231759:web:8ca9c98327f79fed7928fa",
  measurementId: "G-K3PTRDX8X9"
};

// Initialize Firebase
initializeApp(firebaseConfig);


const app = createApp(App)

app.use(router)
app.mount('#app')
